import * as React from "react";

import { StaticImage } from "gatsby-plugin-image";
import TwoImages from "../../components/Layout/TwoImages";

const ArtBeton = () => {
  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "30px",
      }}
    >
      <h1
        style={{ textAlign: "center", fontSize: "36px", marginBottom: "30px" }}
      >
        Art Beton
      </h1>
      <p style={{ fontSize: "18px", lineHeight: "1.5" }}>
        Art Beton is a type of polished concrete finish that is used to create a
        unique and decorative look in both commercial and residential spaces.
        This finish is achieved by applying a concrete overlay to an existing
        concrete surface, and then polishing it to a high shine. The overlay can
        be tinted or pigmented to create a wide range of colors and designs. Art
        Beton is a versatile finish that can be used to create a variety of
        looks, from modern and minimalist to rustic and industrial. It is
        durable, easy to maintain, and can be used to create a seamless look
        throughout a space. This finish can also be used to repair and level out
        concrete surfaces, creating an even surface before polishing it. One of
        the advantages of using Art Beton is that it is an eco-friendly option,
        since it's a concrete based finish and it doesn't require the use of any
        chemicals or solvents. It also adds value to a property, and increases
        its aesthetic appeal. It is important to note that like any polished
        concrete, Art beton requires a skilled applicator and specialized
        equipment to achieve the desired finish. It also can be a long process,
        and it's not suitable for every type of concrete surface. In conclusion,
        Art Beton is a unique and decorative finish that can be used to enhance
        the look and feel of any space. It's a durable, easy to maintain, and
        eco-friendly option that adds value to a property. However, it requires
        a skilled applicator and specialized equipment, and it's not suitable
        for every type of concrete surface.
      </p>

      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_6_600x800.jpg"
          placeholder="blurred"
          width={600}
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_2_800x600.jpg"
          placeholder="blurred"
          width={600}
        />
      </TwoImages>

      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_1_800x600.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_5_800x600.jpg"
          placeholder="blurred"
        />
      </TwoImages>
      <TwoImages>
        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          src="../images/finishes-page/art beton/art_beton_3_800x600.jpg"
          placeholder="blurred"
        />

        <StaticImage
          formats={["auto", "webp", "avif"]}
          alt="art beton finish sample"
          placeholder="blurred"
          src="../images/finishes-page/art beton/art_beton_4_600x889.jpg"
        />
      </TwoImages>
    </div>
  );
};

export default ArtBeton;
